import React from 'react'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'

import { getLogo, scrollTo } from '~/utils/helpers'

import NewsletterPrompt from '~/components/NewsletterPrompt/NewsletterPromptGql'
import PictureTag from '~/components/PictureTag'
import Link, { Redirect } from '~/components/Link'

import config from '~/config'

@withNamespaces(['common'], { withRef: true })
export default class Footer extends React.Component {
  constructor (props) {
    super(props)

    this.footerEl = React.createRef()
  }

  componentDidMount () {
    const { observer } = this.props
    observer && observer.observe(this.footerEl.current)
  }

  componentDidUpdate (prevProps) {
    if (this.props.observer && prevProps.observer !== this.props.observer) {
      this.props.observer.observe(this.footerEl.current)
    }
  }

  render () {
    const { modifiers, t, festivalName, document } = this.props

    const getPfr = (festivalName) => {
      const customFestivals = ['cc']

      if (customFestivals.includes(festivalName)) {
        return `assets/pfr/pfr-${festivalName}.png`
      }

      return 'assets/pfr/pfr.png'
    }

    return (
      <footer
        ref={this.footerEl}
        id='footer'
        className={bemCx('footer', modifiers)}
      >
        <div className='container footer__container'>
          <PictureTag
            src={getPfr(festivalName)}
            className='footer__pfr-img-mobile'
            alt='Logo PFR'
            blankMediaQuery='(min-width: 420px)'
          />
          <div className='footer__links-container'>
            <div>
              <PictureTag
                src={getLogo(festivalName, true)}
                blankMediaQuery='(max-width: 991px)'
                className='footer__logo-img'
                alt={`Logo ${festivalName || 'Restaurant Club'}`}
              />
              <div className='footer__company'>
                <p className='newsletter-prompt__feature'>
                  Restaurant Club Sp. z o. o.
                </p>
                <p className='newsletter-prompt__feature'>
                  Aleje Ujazdowskie 24 lok. 23a
                </p>
                <p className='newsletter-prompt__feature'>
                  00-478 Warszawa
                </p>
                <p className='newsletter-prompt__feature'>
                  {config().festival.email}
                </p>
                <p className='newsletter-prompt__feature'>
                  +48 22 113 43 00
                </p>
                <p className='footer__partner'>
                  {t('footer.partner.payments')}
                </p>
                <a href='https://poland.payu.com/' target='_blank'>
                  <PictureTag
                    src='assets/logo-payu-partner.svg'
                    className='footer__partner-logo'
                    alt='Logo Payu'
                  />
                </a>
                <p className='footer__partner'>
                  {t('footer.partner.technology')}
                </p>
                <PictureTag
                  src='assets/sales-manago-logo.png'
                  className='footer__partner-logo'
                  alt='Logo Sales Manago'
                />
                <PictureTag
                  src={getPfr(festivalName)}
                  className='footer__pfr-img'
                  alt='Logo PFR'
                  blankMediaQuery='(max-width: 420px)'
                />
              </div>
            </div>
            <ul className='footer__links'>
              {document.id && <li className='footer__link'>
                <Redirect
                  route='regulation'
                  params={{ id: document.id }}
                  newClient
                  slug
                >
                  {t('footer.links.privacy_policy')}
                </Redirect>
              </li>}
              <li className='footer__link'>
                <Redirect route='regulations' newClient>
                  {t('footer.links.terms')}
                </Redirect>
              </li>
              <li className='footer__link'>
                <Redirect route='faq' newClient>
                  {t('footer.links.contact')}
                </Redirect>
              </li>
              {config().klarna.client_id && config().klarna.enableBranding &&
                <li className='footer__link'>
                  <Redirect route='klarna-help' newClient>
                    {t('footer.links.klarna')}
                  </Redirect>
                </li>
              }
              <li className='footer__link'>
                <Link route='become-partner'>
                  {t('footer.links.become_partner')}
                </Link>
              </li>
              <li className='footer__link'>
                <Redirect route='weekly' newClient>
                  {t('footer.links.blog')}
                </Redirect>
              </li>
              <li className='footer__link'>
                <a
                  className='footer__scroll-to-top scroll-to-top'
                  onClick={scrollTo('body')}
                >
                  <span className='scroll-to-top__text'>{t('footer.links.back_to_top')}</span>
                  <span className='scroll-to-top__arrow i-arrowup' />
                </a>
              </li>
            </ul>
          </div>
          <NewsletterPrompt
            placeholder={t('footer.newsletter_prompt.placeholder')}
            source='footer'
          />
        </div>
      </footer>
    )
  }
}
