import React, { useState } from 'react'
import { withNamespaces } from '>/i18n'
import { withFormik, Field, Form } from 'formik'
import bemCx from 'bem-modifiers'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'
import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import InputField from '~/components/form/InputField'
import { Button } from '~/components/Button'

import { newsletterSchema as validationSchema } from '~/utils/validation'
import { setCookies } from '~/utils/cookie'
import { setSalesmanagoCookie } from '~/utils/helpers'
import { MODAL_NAMES } from '~/utils/consts'

const handleSubmit = async (value, { props, resetForm }) => {
  const { openModal, closeThisModal, newsletterSignUp, source } = props

  const { data } = await newsletterSignUp({
    variables: {
      email: value.newsletter,
      source,
    }
  })
  const salesmanagoContactId = get(data, 'newsletterSignUp.salesmanagoContactId') || ''

  setSalesmanagoCookie({ salesmanagoContactId })
  closeThisModal && closeThisModal()
  openModal(MODAL_NAMES.NEWSLETTER)
  setCookies('newsletter', true)
  resetForm({ newsletter: '' })
}

const More = ({ t }) => {
  const [more, setMore] = useState(false)

  return (
    <>
      <div className='newsletter-prompt__info'>
        {t('footer.newsletter_prompt.privacy_policy_info')}
        {more && <>
          <span className='newsletter-prompt__info-more-text'>
            {' '}{t('footer.newsletter_prompt.privacy_policy_info_more')}
          </span>
        </>}
        <div
          className='newsletter-prompt__info-more-container'
          onClick={() => setMore(!more)}
        >
          <span className='newsletter-prompt__info-more'>
            {more ? t('footer.newsletter_prompt.hide') : t('footer.newsletter_prompt.extend')}
          </span>
        </div>
      </div>
    </>
  )
}

@withFestivalContext
@withGraphModalActions
@withFormik({
  name: 'newsletterForm',
  mapPropsToValues: () => ({ newsletter: '' }),
  validationSchema,
  handleSubmit,
})
@withNamespaces(['common'])
export default class NewsletterPromptContainer extends React.Component {

  get features () {
    const { festivalName } = this.props
    if (festivalName === 'bfw') {
      return [
        'feature_1',
        'feature_2',
        'feature_4',
      ]
    } else {
      return [
        'feature_1',
        'feature_2',
        'feature_3',
        'feature_4',
      ]
    }
  }

  render () {
    const { t, modifiers, placeholder, label, isSubmitting, privacyPolicyId } = this.props

    return (
      <Form className={bemCx('newsletter-prompt', modifiers)}>
        <h2 className='newsletter-prompt__title'>
          {t('footer.newsletter_prompt.title')}
        </h2>
        <div className='newsletter-prompt__input-container'>
          <Field
            name='newsletter'
            modifiers={['newsletter', modifiers]}
            component={InputField}
            label={label}
            placeholder={placeholder}
          />
          <Button
            type='submit'
            className='newsletter-prompt__button'
            modifiers={['primary', 'newsletter', 'small', modifiers]}
            label={t('button.join')}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
        {privacyPolicyId && <More t={t} />}
        <div className='newsletter-prompt__label'>
          {t('footer.newsletter_prompt.label')}
        </div>
        <ul className='newsletter-prompt__features-list'>
          {this.features.map(feature => (
            <li key={feature} className='newsletter-prompt__feature'>
              <div className='newsletter-prompt__icon i-tick' />
              <div className='newsletter-prompt__text'>
                {t(`footer.newsletter_prompt.features.${feature}`)}
              </div>
            </li>
          ))}
        </ul>
      </Form>
    )
  }
}
