import { withNamespaces } from '>/i18n'
import withFestivalContext from '~/hoc/withFestivalContext'
import { isEmpty, compose } from 'ramda'
import { getFestivalRange, getFestivalRangeShort } from '~/utils/helpers'

const FestivalInfo = ({
  withPrice = true,
  withDate = true,
  festival = {},
  withRespect,
  withCustomRespect = false,
  festivalName,
  className,
  t,
}) => {
  if (!festival || isEmpty(festival)) return null
  const extra = (festivalName === 'cc' || festivalName === 'fdw') ? `${t('from')} ` : ''
  const pricePerPerson = t('price_per_person')

  if (festivalName === 'fdw') {
    return (
      <div className={`festival-info ${className}`}>
        <span>{t('fdw_festival_info')}{getFestivalRangeShort(festival)}</span>
      </div>)
  }

  return (
    <div className={`festival-info ${className}`}>
      {withRespect && <span
        className='hero-section__respect-tag'
        dangerouslySetInnerHTML={{ __html: t(`respect_tag.${withCustomRespect ? festivalName : 'default'}`) }}
      />}
      {withDate && <span>{getFestivalRange(festival)}</span>}
      {festival.price && withPrice && withDate && <span className={`${className}-separator`}> — </span>}
      {festival.price && withPrice &&
      <span>{`${extra}${festival.price} ${pricePerPerson}`}</span>}
    </div>
  )
}

export default compose(
  withNamespaces('common'),
  withFestivalContext
)(FestivalInfo)
