import React from 'react'
import { withNamespaces } from '>/i18n'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'
import ImageTag from '~/components/ImageTag'

@withNamespaces('homepage-fdw')
export default class ExplanationSection extends React.Component {

  get steps () {
    return [
      'step_1',
      'step_2',
      'step_3'
    ]
  }

  render () {
    const { t } = this.props

    return (
      <HomepageSectionWrapper
        title={t('explanation_section.title')}
        description={t('explanation_section.description')}
        sectionId='explanation-section'
        modifiers='explanation'
      >
        <div className='explanation-section'>
          {this.steps.map((item) =>
            <div
              key={item}
              className='explanation-section__item'
            >
              <div className='explanation-section__icon'>
                <ImageTag
                  src={`assets/landing-fdw/how-it-works/${item}.svg`}
                  alt={t(`explanation_section.steps.${item}.heading`)}
                />
              </div>
              <div className='explanation-section__item-heading'>
                {t(`explanation_section.steps.${item}.heading`)}
              </div>
              <div
                className='explanation-section__item-description'
                dangerouslySetInnerHTML={{ __html: t(`explanation_section.steps.${item}.description`) }}
              />
            </div>
          )}
        </div>
      </HomepageSectionWrapper>
    )
  }
}
