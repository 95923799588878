import React, { useState } from 'react'
import { withNamespaces } from '>/i18n'

import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'
import FestivalInfo from '~/components/FestivalInfo'
import { get } from 'lodash'
import { LinkButton } from '~/components/Button'
import { FESTIVAL_EDITION_STATES } from '~/utils/consts'
import { getNewClientEarlyReservationLink, getNewClientListingLink } from '../../../../src/utils/newClient'

export default withNamespaces('homepage-fdw')(({ festivalData, t }) => {
  const [loading, setLoading] = useState(false)
  const isPresale = get(festivalData, 'state') === FESTIVAL_EDITION_STATES.PRESALE

  return (
    <div className='take-part-section'>
      <div className='take-part-section__content container'>
        <FestivalInfo
          festival={festivalData}
          className='take-part-section__event'
        />
        <div
          className='take-part-section__title'
          dangerouslySetInnerHTML={{ __html: t('take_part.title') }}
        />
        <div className='take-part-section__select'>
          <GeolocationDropdownSelect disabled={!festivalData} />
          <LinkButton
            className='geolocation-dropdown-select__button'
            modifiers={['primary', 'hero', 'full-width']}
            route={
              isPresale
                ? getNewClientEarlyReservationLink(get(festivalData, 'id'))
                : getNewClientListingLink('fdw')
            }
            label={
              festivalData === null
                ? t('hero_section.no_festival_button')
                : t('hero_section.search_button')
            }
            onClick={() => setLoading(true)}
            loading={loading}
            disabled={loading || !festivalData}
            prefetch
          />
        </div>
      </div>
    </div>
  )
})
