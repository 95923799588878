import React from 'react'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'
import HeroVoucherContent from '../HeroVoucherContent'
import HeroFestivalContent from '../HeroFestivalContent'
import Swiper from 'react-id-swiper'
import PictureTag from '~/components/PictureTag'

import { scrollTo } from '~/utils/helpers'
import { EXPLANATION_SECTION_TOP_MARGIN, FESTIVAL_EDITION_STATES } from '~/utils/consts'

const Content = ({ isVoucher, ...props }) => {
  return isVoucher
    ? <HeroVoucherContent isVoucher={isVoucher} {...props} />
    : <HeroFestivalContent {...props} />
}

const slides = [
  { src: 'slider-fdw-1.jpg', href: 'https://restaurantclub.pl/restaurants/festival/fine-dining-week' },
  { src: 'slider-fdw-2.jpg', href: 'https://restaurantclub.pl/restaurants/festival/fine-dining-week' },
  { src: 'slider-fdw-3.jpg', href: 'https://restaurantclub.pl/restaurants/festival/fine-dining-week' },
  { src: 'slider-fdw-4.jpg', href: 'https://restaurantclub.pl/restaurants/festival/fine-dining-week' }
]

const TIMEOUT_DURATION = 3000

@withLandingNamespaces()
export class HeroSection extends React.Component {

  componentDidMount () {
    this.initSwiper()
    this.changeAdSlide()
  }

  componentWillUnmount () {
    this.swiper && this.swiper.destroy()
    clearInterval(this.adInterval)
  }

  initSwiper = () => {
    this.swiper && this.swiper.init()
  }

  updateSwiper = () => {
    this.swiper && this.swiper.update()
  }

  changeAdSlide = () => {
    let i = 0
    const lastSlide = slides ? slides.length - 1 : 0
    this.adInterval = setInterval(() => {
      if (i < lastSlide) {
        this.updateSwiper()
        this.swiper && this.swiper.slideNext()
        i++
      } else {
        clearInterval(this.adInterval)
      }
    }, TIMEOUT_DURATION)
  }

  render () {
    const { festivalEditionState, t } = this.props
    const isVoucher = festivalEditionState === FESTIVAL_EDITION_STATES.VOUCHER

    const swiperParams = {
      init: false,
      effect: 'fade',
      speed: 500,
      pagination: {
        type: 'bullets',
        el: '.hero-section__pagination',
        bulletClass: 'hero-section__bullet',
        bulletActiveClass: 'hero-section__bullet--active'
      },
      on: {
        slideChange: () => {
          this.updateSwiper()
        },
      }
    }

    return (
      <section className={bemCx('hero-section', { 'voucher': isVoucher })}>
        <div className='hero-section__container'>
          <div className='hero-section__content-wrapper '>
            <div className='hero-section__content'>
              <Content isVoucher={isVoucher} {...this.props} />
            </div>
            <div
              className='hero-section__caret'
              onClick={scrollTo('explanation-section', { duration: 350, offset: -EXPLANATION_SECTION_TOP_MARGIN })}
            >
              <span className='hero-section__caret-icon i-fat-arrow' />
              {t('hero_section.find_out_more')}
            </div>
          </div>
          <div className='hero-section__swiper'>
            <Swiper {...swiperParams} ref={node => { if (node) { this.swiper = node.swiper } }}>
              {slides && slides.map((slide, index) => {
                const slideSrc = slide.src || slide
                const slideHref = slide.href || null
                return (<PictureTag
                  key={slideSrc}
                  src={`assets/${slideSrc}`}
                  href={slideHref}
                  blankMediaQuery='(max-width: 767px)'
                  pictureClassName='swiper-slide'
                  className='hero-section__slide-img'
                />)
              })}
            </Swiper>
          </div>
        </div>
      </section>
    )
  }

}

export default withNamespaces('homepage-fdw')(HeroSection)
