import React from 'react'
import { isEmpty } from 'ramda'

import LogosSectionView from './LogosSectionView'

import { updateQueryParam } from '~/utils/helpersWithoutRouter'
import { groupTagsByCategory, scrollTo } from '~/utils/helpers'
import { SPONSOR } from '~/utils/consts'

export default class LogosSection extends React.Component {
  state = {
    shouldBeLoading: true,
  }

  componentDidMount () {
    this.setShouldBeLoading(this.props.loading)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setShouldBeLoading(this.props.loading)
    }
  }

  setShouldBeLoading = bool => this.setState({ shouldBeLoading: bool })

  scrollToLogos = () => {
    const { logos = [], router } = this.props
    const { query } = router
    const { scrl } = query

    if (scrl && logos.length) {
      scrollTo('partners-section')()
      // ugly hack with timeout, because Velocity complete callback is called too early
      setTimeout(() => updateQueryParam(query, router, ['scrl'], 'replace'), 500)
    }
  }

  get sponsors () {
    const { logos = [] } = this.props

    const logosMap = groupTagsByCategory(logos, 'kind')
    const partnersMap = groupTagsByCategory(logosMap[SPONSOR.PARTNER], 'subkind')
    const sponsors = Object.keys(partnersMap).reduce((acc, key) => {
      if (key !== SPONSOR.REGULAR) {
        acc[key] = partnersMap[key]
      }
      return acc
    }, {})

    return {
      mainSponsors: logosMap[SPONSOR.MAIN],
      sponsors,
      partners: partnersMap[SPONSOR.REGULAR],
      patrons: logosMap[SPONSOR.PATRON],
      regionalPatrons: logosMap[SPONSOR.REGIONAL],
      plebiscitePartners: logosMap[SPONSOR.PLEBISCITE_PARTNER]
    }
  }

  render () {
    const { logos = [], loading } = this.props

    if (isEmpty(logos)) return null

    const loadedContent = !(loading || this.state.shouldBeLoading)

    return (loadedContent &&
      <LogosSectionView
        {...this.props}
        mainSponsors={this.sponsors.mainSponsors}
        sponsors={this.sponsors.sponsors}
        partners={this.sponsors.partners}
        patrons={this.sponsors.patrons}
        regionalPatrons={this.sponsors.regionalPatrons}
        plebiscitePartners={this.sponsors.plebiscitePartners}
        scrollToLogos={this.scrollToLogos}
      />)
  }
}
