import { useEffect } from 'react'
import { withNamespaces } from '>/i18n'
import { compose, isEmpty } from 'ramda'
import bemCx from 'bem-modifiers'

import PartnersSection from '~/components/PartnersSection'
import MediaPatronageSection from '~/components/MediaPatronageSection'

const LogosSectionView = ({
  t,
  mainSponsors,
  sponsors,
  partners,
  patrons,
  regionalPatrons,
  plebiscitePartners,
  pageModifier,
  inView,
  scrollToLogos
}) => {
  useEffect(() => {
    scrollToLogos()
  }, [])

  return <div className={bemCx('logos-section__content', pageModifier)} id='partners-section'>
    {(mainSponsors || !isEmpty(sponsors)) && <PartnersSection
      mainSponsors={mainSponsors}
      sponsors={sponsors}
      pageModifier={pageModifier}
      inView={inView}
    />}
    {partners && <MediaPatronageSection
      title={t('partners_section.title')}
      options={partners}
      pageModifier={pageModifier}
      inView={inView}
    />}
    {plebiscitePartners && <MediaPatronageSection
      title={t('plebiscite_partner.title')}
      options={plebiscitePartners}
      pageModifier={pageModifier}
      inView={inView}
    />}
    {patrons && <MediaPatronageSection
      title={t('media_coverage_section.title')}
      options={patrons}
      pageModifier={pageModifier}
      inView={inView}
    />}
    {regionalPatrons && <MediaPatronageSection
      title={t('regional_patron.title')}
      options={regionalPatrons}
      pageModifier={pageModifier}
      inView={inView}
    />}
  </div>
}

export default compose(
  withNamespaces('common'),
)(LogosSectionView)
