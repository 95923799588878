import React from 'react'
import bemCx from 'bem-modifiers'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'
import PictureTag from '~/components/PictureTag'

@withLandingNamespaces()
export default class WeOfferSection extends React.Component {

  render () {
    const { t, offers, decorator, bold = [] } = this.props

    return (
      <section className='we-offer-section'>
        <div className='we-offer-section__background'>
          <HomepageSectionWrapper
            modifiers='offer'
            title={t('we_offer_section.title')}
          >
            <p
              className='we-offer-section__description'
              dangerouslySetInnerHTML={{ __html: t('we_offer_section.description') }}
            />
            <div className='we-offer-section__content'>
              {Object.keys(offers).map((key, i) => (
                <div
                  key={key}
                  className='we-offer-section__item'
                >
                  <div
                    className={bemCx('we-offer-section__icon', { 'bold': bold.includes(i + 1) }, `i-${offers[key]}`)}
                  />
                  <p className='we-offer-section__item-heading'
                    dangerouslySetInnerHTML={{ __html: t(`we_offer_section.offers.${key}.heading`) }}
                  />
                  <p className='we-offer-section__item-description'
                    dangerouslySetInnerHTML={{ __html: t(`we_offer_section.offers.${key}.description`) }}
                  />
                </div>
              ))}
              {decorator && <PictureTag
                className='we-offer-section__decorator'
                src={`assets/${decorator}`}
                blankMediaQuery='(max-width: 991px)'
              />}
            </div>
          </HomepageSectionWrapper>
        </div>
      </section>
    )
  }
}
