import React from 'react'
import bemCx from 'bem-modifiers'

import PictureTag from '~/components/PictureTag'

export default class PhotoSection extends React.Component {

  render () {
    return (
      <section className='photo-section'>
        <>
          <PictureTag
            pictureClassName={bemCx('photo-section__photo-wrapper', 'left')}
            className='photo-section__photo'
            src='assets/image-left.jpg'
            blankMediaQuery='(max-width: 479px)'
            alt='Wnętrze lokalu'
          />
          <PictureTag
            pictureClassName={bemCx('photo-section__photo-wrapper', 'right')}
            className='photo-section__photo'
            src='assets/image-right.jpg'
            blankMediaQuery='(max-width: 479px)'
            alt='Wnętrze lokalu'

          />
          <PictureTag
            pictureClassName={bemCx('photo-section__photo-wrapper', 'mobile')}
            className='photo-section__photo'
            src='assets/image-mobile.jpg'
            blankMediaQuery='(min-width: 480px)'
            alt='Wnętrze lokalu'
          />
        </>
      </section>
    )
  }
}
