import { withNamespaces } from '>/i18n'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'
import FestivalInfo from '~/components/FestivalInfo'

import { GET_LOCATION } from '~/utils/queries.gql'
import { formatLocationQuery } from '~/utils/helpers'
import { FESTIVAL_EDITION_STATES } from '~/utils/consts'
import { getNewClientEarlyReservationLink, getNewClientListingLink } from '../../../src/utils/newClient'
import { LinkButton } from '../../../src/components/Button'

export default withNamespaces('homepage-fdw')(({ festivalData, t }) => {
  const [loading, setLoading] = useState(false)

  const locationData = useQuery(GET_LOCATION)
  const location = get(locationData, 'data.location')
  const locationStr = formatLocationQuery(location)

  const redirectParams = {
    location: locationStr,
    festivalFilter: get(festivalData, 'id')
  }

  const isPresale = get(festivalData, 'state') === FESTIVAL_EDITION_STATES.PRESALE

  return (
    <div className='hero-festival-content'>
      <FestivalInfo
        festival={festivalData}
        className='hero-festival-content__event'
      />
      <p
        className='hero-voucher-content__event-extra'
        dangerouslySetInnerHTML={{ __html: t('hero_section.event-extra') }}
      />
      <h1
        className='hero-festival-content__title'
        dangerouslySetInnerHTML={{ __html: t('hero_section.title') }}
      />
      <p
        className='hero-voucher-content__info'
        dangerouslySetInnerHTML={{ __html: t('hero_section.info') }}
      />
      <div className='hero-festival-content__search-text'>
        {t('hero_section.book_online')}
      </div>
      <div className='hero-festival-content__filters'>
        <GeolocationDropdownSelect disabled={festivalData === null} />
        <LinkButton
          className='geolocation-dropdown-select__button'
          modifiers={['primary', 'hero', 'full-width']}
          route={
            isPresale
              ? getNewClientEarlyReservationLink(get(festivalData, 'id'), redirectParams)
              : getNewClientListingLink('fdw', redirectParams)
          }
          label={
            festivalData === null
              ? t('hero_section.no_festival_button')
              : t('hero_section.search_button')
          }
          onClick={() => setLoading(true)}
          loading={loading}
          disabled={loading || !festivalData}
          prefetch
        />
      </div>
      <span className='hero-voucher-content__fee'>{t('hero_section.fee')}</span>
    </div>
  )
})
