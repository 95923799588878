import React from 'react'
import Head from 'next/head'
import get from 'lodash/get'
import { Query } from '@apollo/client/react/components'
import { GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'
import { withNamespaces } from '>/i18n'
import withLayout from '~/hoc/withLayout'
import withFestivalContext from '~/hoc/withFestivalContext'

import Footer from '~/components/Footer/FooterGql'
import LogosSection from '~/components/LogosSection/LogosSectionGql'
import WeOfferSection from './WeOfferSection'
import VoucherSection from './VoucherSection'
import ExplanationSection from './ExplanationSection'
import HeroSection from './HeroSection/HeroSectionGql'
import PhotoSection from './PhotoSection'
import TakePartSection from './TakePartSection/TakePartSectionGql'

import { getFestivalRangeShort } from '~/utils/helpers'
import { FESTIVAL_EDITION_STATES, DESCRIPTION_LIMIT } from '~/utils/consts'
import truncate from 'lodash/truncate'
import stripHtml from 'string-strip-html'

import Modal from '~/components/Modal'
import { commonModals } from '~/components/Modal/modalsList'
import config from '~/config'

const OFFERS = {
  offer_1: 'percent',
  offer_2: 'cutlery',
  offer_3: 'book',
  offer_4: 'reservation',
  offer_5: 'cocktail',
  offer_6: 'time'
}

const HeadTags = ({ festival }) => {
  const { festivalName, t, state } = festival

  const description = state === FESTIVAL_EDITION_STATES.VOUCHER ?
    t(`app.${festivalName}.description_inactive`) :
    t(`app.${festivalName}.description`, { range: getFestivalRangeShort(festival) })

  const formattedName = `${t(`app.${festivalName}.title`)}`
  const formattedDescription = truncate(stripHtml(description).result, {
    'length': DESCRIPTION_LIMIT,
    'separator': ' '
  })
  const image = `${config().clubUrl}/static/assets/festival/${festivalName}/share.png` || undefined

  return (
    <Head>
      <title>{formattedName}</title>
      <meta name='description' content={formattedDescription} />
      <meta property='og:title' content={formattedName} key='title' />
      <meta property='og:description' content={formattedDescription} key='description' />
      {image && <meta property='og:image' content={image} key='image' />}
      {/* FDW Meta Pixel Code */}
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1624967884669679');
            fbq('track', 'PageView');`
        }}
      />
    </Head>
  )
}

export default
@withLayout({
  namespaces: ['tooltips', 'modal'],
  contentClassName: 'festival'
})
@withFestivalContext
@withNamespaces(['meta', 'homepage-fdw'])
class Index extends React.Component {
  render () {
    const { festivalName, t } = this.props
    const festival = config(festivalName).festival

    return <div>
      <Query
        query={GET_CURRENT_FESTIVAL_EDITION}
        variables={{ code: festivalName }}
      >
        {({ data }) => {
          const isVoucher = get(data, 'currentFestivalEdition.state') === FESTIVAL_EDITION_STATES.VOUCHER

          return (<>
            <HeadTags festival={{ festivalName, t, ...get(data, 'currentFestivalEdition') }} />
            <HeroSection />
            {isVoucher &&
              <div className='container'>
                <VoucherSection />
              </div>}
            <div className='container'>
              <ExplanationSection />
            </div>
            <PhotoSection />
            <WeOfferSection offers={OFFERS} />
            <div className='container'>
              <LogosSection loadImmediately />
            </div>
            {!isVoucher && <TakePartSection />}
            <Footer />
            {config().chatbotHash && festival.chatbotEnabled && <script async type='text/javascript' src={`https://cdn.thulium.com/apps/chat-widget/chat-loader.js?hash=${config().chatbotHash}`} />}
          </>)
        }}
      </Query>
      <Modal components={commonModals} />
    </div>
  }
}
