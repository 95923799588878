import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'

import HeroSectionContainer from './HeroSectionContainer'

import { GET_FESTIVAL_REGIONS, GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

export default withFestivalContext(({ router, festivalName, festivalId, ...props }) => {

  return (
    <Query
      query={GET_CURRENT_FESTIVAL_EDITION}
      variables={{ code: festivalName }}
      skip={!festivalName}
    >
      {({ data: festivalData }) => {

        return (
          <Query
            query={GET_FESTIVAL_REGIONS}
            variables={{ festivalEditionId: festivalId }}
            skip={!festivalId}
          >
            {({ data: regionsData }) => {
              return (
                <HeroSectionContainer
                  {...props}
                  festivalName={festivalName}
                  regions={get(regionsData, 'festivalRegions')}
                  festivalData={get(festivalData, 'currentFestivalEdition')}
                  festivalEditionState={get(festivalData, 'currentFestivalEdition.state')}
                />
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
})
