import React from 'react'
import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'
import { compose } from 'ramda'
import bemCx from 'bem-modifiers'

import withFestivalContext from '~/hoc/withFestivalContext'

import LogosSectionContainer from './LogosSectionContainer'

import { GET_LOCATION, GET_SPONSORS } from '~/utils/queries.gql'

class LogosSectionGql extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      inView: false
    }

    this.logosSection = React.createRef()
  }

  componentDidMount () {
    this.initIntersectionObserver()
    this.observer.observe(this.logosSection.current)
  }

  componentWillUnmount () {
    this.observer.disconnect()
  }

  initIntersectionObserver = () => {
    const config = {
      rootMargin: '500px',
    }

    this.observer = new IntersectionObserver(this.setInView, config)
  }

  setInView = entries => {
    const { restaurantsList, pageModifier } = this.props
    const isIntersecting = get(entries, '[0].isIntersecting')

    if (isIntersecting && !this.state.inView) {
      pageModifier
        ? restaurantsList && this.setState({ inView: true })
        : this.setState({ inView: true })
    }
  }

  render () {

    const { inView } = this.state
    const { regionId, festivalId, festivalName, pageModifier, router, loadImmediately, ...props } = this.props

    const festivalEditionId = festivalId || router.query.festivalFilter
    const scrollForced = router.query.scrl

    return (
      <div
        ref={this.logosSection}
        className={bemCx('logos-section', pageModifier)}
      >
        <Query
          query={GET_LOCATION}
        >
          {({ data: locationData }) => {
            const regionId = get(locationData, 'location.id')

            return (
              <Query
                query={GET_SPONSORS}
                variables={{
                  festivalEditionId,
                  regionId: pageModifier && regionId,
                  // it's easier for backend now if 'clubSponsors' is 'undefined' instaed of be 'false'
                  clubSponsors: !festivalName ? true : undefined
                }}
                skip={(!loadImmediately && !inView && !scrollForced) || (festivalName && !festivalId)}
              >
                {({ data: logosData, loading }) => {

                  return (
                    <LogosSectionContainer
                      {...props}
                      logos={get(logosData, 'sponsors.nodes')}
                      loading={loading}
                      pageModifier={pageModifier}
                      inView={inView}
                      router={router}
                    />
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default compose(
  withFestivalContext
)(LogosSectionGql)
